import ApexCharts from 'apexcharts';
export class PieChart {
    public option = {};
    public element: string;
    public chartInstance: ApexCharts;

    public buildOptions(data: any) {
        return {
            chart: {
                width: 250,
                type: "pie",
                marginTop: 5,
                fontFamily: "Conv_Montserrat-Regular"
            },
            stroke: {
                show: true,
                curve: "smooth",
                lineCap: "butt",
                colors: '#18191D1',
                width: 1,
                dashArray: 0
            },
            ...data,
            legend: false,
            /**
             * Customize how to render data labels
             */
            dataLabels: {
                formatter: function (val, opts) {
                    return opts.w.config.series[opts.seriesIndex];
                }
            },
            /**
             * Handle chart's responsive
             */
            responsive: [
            
                {
                    breakpoint: 1200,
                    options: {
                        chart: {
                            width: 280,
                            height: 170,
                            marginRight: 15
                        }
                    }
                },

                {
                    breakpoint: 992,
                    options: {
                        chart: {
                            width: 340,
                            height: 200,
                            marginRight: 15
                        }
                    }
                },
                {
                    breakpoint: 767,
                    options: {
                        chart: {
                            width:260,
                            height: 210,
                            marginRight: 15
                        }
                    }
                },
                {
                    breakpoint: 575,
                    options: {
                        chart: {
                            width:220,
                            height: 200,
                            marginRight: 15
                        }
                    }
                }
            ]
        }
    }

    constructor(data: any, elem: string) {
        this.element = elem;
        this.option = this.buildOptions(data);
        this.renderDefault()
    }

    renderDefault() {
        this.chartInstance = new ApexCharts(
            document.querySelector(`#${this.element}`),
            this.option
        );
        this.chartInstance.render();
    }

    updatePieChart(data: any) {
        this.chartInstance.updateOptions(this.buildOptions(data))
    }
}