import ApexCharts from 'apexcharts';

export class LineChartWithDataTables {
    public chartInstance: ApexCharts;

    public buildOptions(data: any) {
        return {
            chart: data.charts,
            colors: data.colors,
            series: data.series,
            dataLabels: {
                enabled: true,
                style: {
                    color: '#ffffff',
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    cssClass: 'apexcharts-xaxis-title',
                },
                formatter: function (y) {
                    return y != 0 && y != null ? y : "";
                },

            },
            tooltip: {
                enabled: true,
                fillSeriesColor: true,
                theme: 'dark'
            },
            stroke: {
                // width: [5, 7, 5,7],
                curve: 'straight',
                // dashArray: [0,0,8,8]
                dashArray: data.stroke
            },
            title: {
                text: data.title,
                align: 'left',
                style: {
                    color: "#00b6ff",
                    fontSize: '12px',
                }
                // color:'#ffffff',
            },
            markers: {
                size: 1,

            },
            xaxis: {
                categories: data.categories,
                labels: {
                    rotate: -60,
                    style: {
                        colors: '#ffffff',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        cssClass: 'apexcharts-xaxis-title',
                    },
                },
                title: {
                    text: data.xTitle,
                    style: {
                        color: '#ffffff',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        cssClass: 'apexcharts-xaxis-title',
                    },
                },
            },
            yaxis: {
                title: {
                    text: data.yTitle,
                    style: {
                        color: '#ffffff',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        cssClass: 'apexcharts-xaxis-title',
                    },
                },
                labels: {
                    rotate: 0,
                    style: {
                        color: '#ffffff',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        cssClass: 'apexcharts-xaxis-title',
                    },
                },
            },
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                showForSingleSeries: true,
                floating: true,
                offsetY: -25,
                offsetX: -5,
                labels: {
                    colors: ['fff'],
                    useSeriesColors: false,
                },
            },
            responsive: [
                // {
                //     breakpoint: 1200,
                //     options: {
                //         chart: {
                //             width: 680,
                //             // height: 170,
                //             // marginRight: 15
                //         }
                //     }
                // },

                {
                    breakpoint: 992,
                    options: {
                        chart: {
                            width: 750,
                            // height: 250,
                            // marginRight: 15
                        }
                    }
                },
                {
                    breakpoint: 957,
                    options: {
                        chart: {
                            width: 700,
                            // height: 250,
                            // marginRight: 15
                        }
                    }
                },
                {
                    breakpoint: 930,
                    options: {
                        chart: {
                            width: 550,
                            // height: 250,
                            // marginRight: 15
                        }
                    }
                },
                {
                    breakpoint: 800,
                    options: {
                        chart: {
                            width: 500,
                            // height: 250,
                            // marginRight: 15
                        }
                    }
                },
                {
                    breakpoint: 767,
                    options: {
                        chart: {
                            width: 450,
                            // height: 210,
                            // marginRight: 15
                        }
                    }
                },
                {
                    breakpoint: 707,
                    options: {
                        chart: {
                            width: 490,
                            // height: 210,
                            // marginRight: 15
                        }
                    }
                },
                {
                    breakpoint: 580,
                    options: {
                        chart: {
                            width: 400,
                            // height: 200,
                            // marginRight: 15
                        }
                    }
                }
            ]
        }
    };

    public renderDefault(data, elem) {
        this.chartInstance = new ApexCharts(
            document.querySelector(`#${elem}`),
            this.buildOptions(data)
        );
        this.chartInstance.render();
    }

    public destroyChart() {
        this.chartInstance.destroy();
    }
}
// }

