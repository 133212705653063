import ApexCharts from 'apexcharts';

export class StrokeChart {
    public chartInstance: ApexCharts;
    public option = {};
    public element: string;
    public buildOptions(data: any) {
        return {
            chart: {
                height: 280,
                width: 180,
                type: 'radialBar',
                offsetY: -10,
                fontSize: '10px',
                paddingTop: 10,
                color: '#ffffff',
            },
            plotOptions: {
                radialBar: {
                    track: {
                        show: true,
                        background: '#FFFFFF',
                        opacity: 0.2,
                        border: 'solid'
                    },
                    startAngle: -90,
                    endAngle: 90,
                    dataLabels: {
                        name: {
                            show: false,
                            fontSize: '16px',
                            color: "#ffffff",
                            offsetY: 20,
                            fontFamily: 'Conv_Montserrat-Regular',
                        },
                        value: {
                            show: true,
                            offsetY: -10,
                            fontSize: '25px',
                            color: "#FFFFFF",
                            fontFamily: 'Conv_Montserrat-Regular',
                            formatter: function (val) {
                                return val + "%";
                            }
                        }
                    }
                }
            },
            // fill: {
            //     type: 'gradient',
            //     gradient: {
            //         shade: 'dark',
            //         colors: "#00b6ff",
            //         shadeIntensity: 0.15,
            //         inverseColors: true,
            //         opacityFrom: 1,
            //         opacityTo: 1,
            //         stops: [0, 50, 65, 100]
            //     },
            // },
            stroke: {
                show: true,
                curve: "smooth",
                lineCap: "butt",
                colors: '#ffffff',
                width: 1,
                dashArray: 2
            },
            series: [data.progress],
            // labels: [data.label],
            // colors: ["#00b6ff"],
            /**
             * Handle chart's responsive
             */
            responsive: [
                {
                    breakpoint: 1199,
                    options: {
                        chart: {
                            width: 230,
                            height: 230,
                            marginRight: 15
                        }
                    }
                },

                {
                    breakpoint: 992,
                    options: {
                        chart: {
                            width: 170,
                            height: 250,
                            marginRight: 15
                        }
                    }
                }
            ]
        }
    }

    constructor(data: any, elem: string) {
        this.element = elem;
        this.option = this.buildOptions(data);
        this.renderDefault()
    }

    renderDefault() {
        this.chartInstance = new ApexCharts(
            document.querySelector(`#${this.element}`),
            this.option
        );
        this.chartInstance.render();
    }

    updateStorkeChart(data: any) {
        this.chartInstance.updateOptions(this.buildOptions(data))
    }

    destroyChart() {
        this.chartInstance.destroy();
    }


}





