


import { Component, Vue } from "vue-property-decorator";
import { PieChart } from "@/views/charts/pie-chart/pie-chart";
import BarChart from "@/views/charts/bar-chart/bar-chart";
import { BASE_API_URL } from "@/config";
import { LoanReports } from "@/models/common.model";
import Axios from "axios";

import '@/vuebar';
import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
let  w = window as any;
const Vuebar = w.Vuebar as any;
Vue.use(Vuebar);;
import { HollowDotsSpinner } from "epic-spinners";
// import OldStrokeChart from "@/views/charts/stroked-chart/old-stroke-chart";
import { StrokeChart } from "@/views/charts/stroked-chart/stroke-chart";
import { ColumnChart } from "@/views/charts/column-chart/column-chart";
import { LineChartWithDataTables } from "@/views/charts/line-chart-with-datatable/line-chart-with-datatable";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

@Component({ components: { HollowDotsSpinner, Datepicker } })
export default class DashboardComponent extends mixins(CommonMixin) {
  /**Start of pieChart variable */
  public hasPieData = false;
  public pieChart: PieChart;
  /**End of pieChart variable */

  /**Start of strokeChart variable */
  public caReceivedInstance: StrokeChart;
  public caReportInstance: StrokeChart;
  public ctcReportInstance: StrokeChart;
  public strokedChart = false;
  /**End of strokeChart variable */

  /**start of  Show Year div variable */
  public showYear = {
    loanOverview: false,
    percentageLoan: false,
    employeeBorrower: false,
    pipeline: false
  };
  /***End of  Show Year div variable */
  public hasBarData = false;
  public loanSetupTurnTime = 0;
  public caTurnTime = 0;
  public refinanceCount = 0;
  public purchaseCount = 0;
  public basis = "Month";
  public brokerCount = null;
  public averageBorkersPullThrough = null;
  public criticalWarningCount = null;
  public pullThroughData: any = [];
  public isPullThroughLoading = false;
  public pullThroughSortBy = null;
  public pullThroughSortType = null;
  public pullThroughAllData: any = [];
  /** Loan Overview basis constant */
  public loanOverview = null;
  /** End Loan Overview basis constant */
  public years: any = [];
  public currentYears = moment(new Date());
  public projectedLoanType = null;
  public firstSelectedMonth = null;
  public firstSelectedYear = null;
  public secondSelectedMonth = null;
  public secondSelectedYear = null;
  public firstDateRangeStartDate = null;
  public firstDateRangeLastDate = null;
  public secondDateRangeStartDate = null;
  public secondDateRangeLastDate = null;
  public possibleYears = [];
  public allMonths = [
    { month: 0, name: "Jan" },
    { month: 1, name: "Feb" },
    { month: 2, name: "Mar" },
    { month: 3, name: "Apr" },
    { month: 4, name: "May" },
    { month: 5, name: "June" },
    { month: 6, name: "July" },
    { month: 7, name: "Aug" },
    { month: 8, name: "Sep" },
    { month: 9, name: "Oct" },
    { month: 10, name: "Nov" },
    { month: 11, name: "Dec" }
  ];
  public startingYearMonths = [
    { month: 5, name: "June" },
    { month: 6, name: "July" },
    { month: 7, name: "Aug" },
    { month: 8, name: "Sep" },
    { month: 9, name: "Oct" },
    { month: 10, name: "Nov" },
    { month: 11, name: "Dec" }
  ];
  public firstSelectedMonthError = null;
  public secondSelectedMonthError = null;
  public lineChartRendered = false;
  public firstSelectedDateRangeError = null;
  public secondSelectedDateRangeError = null;
  public lineChartInstance: any = null;
  public lineChartHollowLoader = false;
  public isNoLoanClosingData = false;

  public columnChartInstance: ColumnChart;

  public employeesBorrowerLoader = false;
  public strokedChartLoader = false;
  public hasCloumnChart = false;
  public epmloyeesBorrowerExist = false;
  public isMoreEmployeesBorrower = false;
  public totalLoanCount = null;
  public year = null;
  public percentageMountCall = false;
  public lsToCa:any = null;
  public CaToCtc = null
  public ctcToClosing = null
  public chartData = null

  get userType() {
    return this.$store.state.sessionObject.type;
  }

  /**
   * For Loan Closed on the using comma seperator
   */
  public addCommas(nStr) {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
  }

  public async percentageOfLoansClose(
    mountCall: boolean = false,
    basis: string = "Year"
  ) {
    try {
      let response = null;
      let conditionalReceived = "Conditional" + "\n" + "Approval Received";
      if (mountCall && basis == "Year") {
        this.percentageMountCall = true;
        this.showYear.percentageLoan = true;
        this.strokedChartLoader = true;
        basis = "years";
      }else{
        this.percentageMountCall =false
      }
      // } else {
      if (basis != "Year") {
        this.showYear.percentageLoan =
          basis == "1" || basis == "2" || basis == "3" || basis == "4"
            ? false
            : this.showYear.percentageLoan;
        this.strokedChart = true;
        this.strokedChartLoader = true;
        response = await Axios.post(
          BASE_API_URL + "superAdminDashboard/percentageOfLoansClose",
          {
            basis: basis,
            mountCall: mountCall
          });
        // }
        this.lsToCa = response.data.lsToCa
        this.CaToCtc = response.data.CaToCtc
        this.ctcToClosing = response.data.ctcToClosing
        this.chartData = response.data
        
        let caRecievedCountInstance = {
          progress: response.data.report.CAReceived,
          label: conditionalReceived
        };
        let caCountInstance = {
          progress: response.data.report.CAReport,
          label: "Conditional Approval"
        };
        let ctcCountInstance = {
          progress: response.data.report.CTCReport,
          label: "Clear to Close"
        };
        this.strokedChartLoader = false;
        if (
          response.data.report.CAReceived == 0 &&
          response.data.report.CAReport == 0 &&
          response.data.report.CTCReport == 0
        ) {
          this.strokedChart = false;
        } else {
          if (mountCall) {
            this.caReceivedInstance = new StrokeChart(
              caRecievedCountInstance,
              "CAReceived"
            );
            this.ctcReportInstance = new StrokeChart(
              ctcCountInstance,
              "CTCReport"
            );
            this.caReportInstance = new StrokeChart(
              caCountInstance,
              "CAReport"
            );
          } else {
            if (this.caReceivedInstance) {
              this.caReceivedInstance.destroyChart();
              this.caReceivedInstance = new StrokeChart(
                caRecievedCountInstance,
                "CAReceived"
              );
            } 
            if (this.caReportInstance) {
              this.caReportInstance.destroyChart();
              this.caReportInstance = new StrokeChart(
                caCountInstance,
                "CAReport"
              );
            } 
            if (this.ctcReportInstance) {
              this.ctcReportInstance.destroyChart();
              this.ctcReportInstance = new StrokeChart(
                ctcCountInstance,
                "CTCReport"
              );
            } 
          }
        }
      } else this.showYear.percentageLoan = true;
    } catch (error) {
      this.strokedChartLoader = false;
      console.log(error);
    }
  }

  /** Function related to Loan Overview Section */
  public async getLoanOverViewReport(basis: any = "Month") {
    this.basis = basis
    
    // Reviewed
    try {
      if (basis != "Year") {
        this.showYear.loanOverview =
          basis == "Week" || basis == "Month" || basis == "Date"
            ? false
            : this.showYear.loanOverview;
        let response = await Axios.post(
          BASE_API_URL + "superAdminDashboard/getLoanOverViewReport",
          { basis: basis });
        this.loanOverview = response.data;
      } else this.showYear.loanOverview = true;
    } catch (error) {}
  }

  public async generatePieChartDataset(basis: string = "all", mountCall) {
    // Reviewed
    try {
      if (basis != "Year") {
        this.showYear.pipeline =
          basis == "all" || basis == "Month" ? false : this.showYear.pipeline;
        this.hasPieData = true;

        let response = await Axios.post(
          BASE_API_URL + "superAdminDashboard/generatePieChartDataset",
          { basis: basis });

        let loanReports = response.data;
        this.totalLoanCount = loanReports.totalLoanCount;
        if (loanReports.pieChartDataset.series.every(v => v == 0)) {
          this.hasPieData = false;
        } else if (mountCall) {
          this.pieChart = new PieChart(loanReports.pieChartDataset, "pieChart");
        } else {
          this.pieChart.updatePieChart(loanReports.pieChartDataset);
        }
      } else {
        this.showYear.pipeline = true;
      }
    } catch (error) {}
  }

  public async loanSetupTurnTimeReport(basis) {
    try {
      let response = await Axios.get(
        BASE_API_URL + "superAdminDashboard/loanSetupTurnTimeReport",
        { params: { basis: basis } }
      );
      this.loanSetupTurnTime = response.data.loanSetupTurnTime;
    } catch (error) {
      console.log(error);
    }
  }

  public async caTurnTimeReport(basis) {
    try {
      let response = await Axios.get(
        BASE_API_URL + "superAdminDashboard/caTurnTimeReport",
        { params: { basis: basis } }
      );
      this.caTurnTime = response.data.caTurnTime;
    } catch (error) {
      console.log(error);
    }
  }

  public async closingTimeFrameReportForRefinance(basis) {
    try {
      let response = await Axios.get(
        BASE_API_URL + "superAdminDashboard/closingTimeFrameReportForRefinance",
        { params: { basis: basis } }
      );
      this.refinanceCount = response.data.refinance;
    } catch (error) {
      console.log(error);
    }
  }

  public async closingTimeFrameReportForPurchase(basis) {
    try {
      let response = await Axios.get(
        BASE_API_URL + "superAdminDashboard/closingTimeFrameReportForPurchase",
        { params: { basis: basis } }
      );
      this.purchaseCount = response.data.purchase;
    } catch (error) {
      console.log(error);
    }
  }
  public async getLoanNotSubmittedCountByBroker() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "superAdminDashboard/getLoanNotSubmittedCountByBroker");
      this.brokerCount = response.data.brokerCount;
    } catch (error) {
      console.log(error);
    }
  }

  public async getAveragePullThroughbyBrokers() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "superAdminDashboard/getAveragePullThroughbyBrokers");
      this.averageBorkersPullThrough = response.data.averagePullThrough;
    } catch (error) {
      console.log(error);
    }
  }
  public async getCriticalWarningCount() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "superAdminDashboard/getCriticalWarningCount");
      this.criticalWarningCount = response.data.criticalWarningCount;
    } catch (error) {
      console.log(error);
    }
  }

  public async getBrokerPullThrough() {
    try {
      this.isPullThroughLoading = true;
      let response = await Axios.post(
        BASE_API_URL + "superAdminDashboard/getBrokerPullThrough",
        {});
      this.pullThroughAllData = response.data.data;
      if (this.pullThroughAllData.length > 0)
        this.sortPullThroughData("pullThrough");
      this.isPullThroughLoading = false;
    } catch (error) {
      this.isPullThroughLoading = false;
      console.log(error);
    }
  }

  public sortPullThroughData(pullThroughBy) {
    this.pullThroughData = [];
    if (pullThroughBy == this.pullThroughSortBy) {
      this.pullThroughSortType =
        this.pullThroughSortType == "des" ? "asc" : "des";
    } else {
      this.pullThroughSortType = pullThroughBy == "brokerName" ? "asc" : "des";
      this.pullThroughSortBy = pullThroughBy;
    }

    if (pullThroughBy == "brokerName") {
      this.pullThroughAllData.sort((a, b) => {
        let first = a.brokerName.replace(" ", "").toLowerCase();
        let second = b.brokerName.replace(" ", "").toLowerCase();
        if (this.pullThroughSortType == "asc") {
          if (first < second) {
            return -1;
          }
          if (first > second) {
            return 1;
          }
          return 0;
        } else {
          if (first < second) {
            return 1;
          }
          if (first > second) {
            return -1;
          }
          return 0;
        }
      });
    } else {
      this.pullThroughAllData.sort((a, b) => {
        if (this.pullThroughSortType == "des") {
          return b[pullThroughBy] - a[pullThroughBy];
        } else {
          return a[pullThroughBy] - b[pullThroughBy];
        }
      });
    }
    let sortLimit =
      this.pullThroughAllData.length >= 10
        ? 10
        : this.pullThroughAllData.length;
    for (let index = 0; index < sortLimit; index++) {
      this.pullThroughAllData[index].monthlyClosed = Math.round(
        this.pullThroughAllData[index].monthlyClosed
      );
      this.pullThroughAllData[index].pullThrough = Math.round(
        this.pullThroughAllData[index].pullThrough
      );
      this.pullThroughData.push(this.pullThroughAllData[index]);
    }
  }

  public async getEmployeesBorrowerData(basis: string = "Week") {
    try {
      if (basis != "Year") {
        this.showYear.employeeBorrower =
          basis == "Week" || basis == "Month" || basis == "Date"
            ? false
            : this.showYear.employeeBorrower;

        if (this.columnChartInstance) {
          this.columnChartInstance.destroyChart();
          this.columnChartInstance = null;
        }
        this.epmloyeesBorrowerExist = true;
        this.employeesBorrowerLoader = true;

        let response = await Axios.post(
          BASE_API_URL + "superAdminDashboard/getEmployeesBorrowerData",
          {
            basis: basis,
            staffId: this.$userId
          });
        this.employeesBorrowerLoader = false;
        if (
          (response.data.barChartDataset.series &&
            response.data.barChartDataset.series.filter(e => e != 0).length >
              0) ||
          (response.data.barChartDataset.inactive &&
            response.data.barChartDataset.inactive.filter(e => e != 0).length >
              0)
        ) {
          // if (!response.data.barChartDataset.series.every(v => v == 0)) {
          // this.hasCloumnChart = true;

          let width = null;
          let widthRange = {
            10: 1200,
            50: 2200,
            100: 2600,
            150: 3000
          };
          if (
            response.data.barChartDataset.series.length <=
            Object.keys(widthRange)[0]
          ) {
            width = 600;
          } else if (
            response.data.barChartDataset.series.length >=
            Object.keys(widthRange)[Object.keys(widthRange).length - 2]
          ) {
            width = 3000;
          } else {
            Object.keys(widthRange).forEach((e, i) => {
              if (
                response.data.barChartDataset.series.length >= Number(e) &&
                response.data.barChartDataset.series.length <
                  Number(Object.keys(widthRange)[i + 1])
              ) {
                width = widthRange[e];
              }
            });
          }
          this.isMoreEmployeesBorrower =
            response.data.barChartDataset.series.length > 40 ? true : false;
          response.data.barChartDataset.width = width;
          this.columnChartInstance = new ColumnChart(
            response.data.barChartDataset,
            "ColumnChart"
          );
        } else {
          this.epmloyeesBorrowerExist = false;
        }
      } else this.showYear.employeeBorrower = true;
    } catch (error) {
      this.employeesBorrowerLoader = false;
      console.log(error);
    }
  }
  public async getActualAndProjectedClosing(requestType) {
    try {
      this.lineChartHollowLoader = true;
      this.isNoLoanClosingData = false;
      if (this.lineChartInstance) {
        this.lineChartInstance.destroyChart();
        this.lineChartInstance = null;
      }
      let firstStartDate = null;
      let firstLastDate = null;
      let secondStartDate = null;
      let secondLastDate = null;
      if (requestType == "view" || requestType == "compare") {
        firstStartDate = moment(
          new Date(this.firstSelectedYear, this.firstSelectedMonth.month, 1)
        ).startOf("month");
        firstLastDate = moment(
          new Date(this.firstSelectedYear, this.firstSelectedMonth.month, 1)
        ).endOf("month");
        if (requestType == "compare") {
          secondStartDate = moment(
            new Date(this.secondSelectedYear, this.secondSelectedMonth.month, 1)
          ).startOf("month");
          secondLastDate = moment(
            new Date(this.secondSelectedYear, this.secondSelectedMonth.month, 1)
          ).endOf("month");
        }
      } else if (
        requestType == "viewDateRanges" ||
        requestType == "compareDateRanges"
      ) {
        firstStartDate = moment(this.firstDateRangeStartDate).startOf("day");
        firstLastDate = moment(this.firstDateRangeLastDate).endOf("day");
        if (requestType == "compareDateRanges") {
          secondStartDate = moment(this.secondDateRangeStartDate).startOf(
            "day"
          );
          secondLastDate = moment(this.secondDateRangeLastDate).endOf("day");
        }
      }

      let response = await Axios.post(
        BASE_API_URL + "superAdminDashboard/getActualAndProjectedClosing",
        {
          requestType: requestType,
          firstStartDate: firstStartDate,
          firstLastDate: firstLastDate,
          secondStartDate: secondStartDate,
          secondLastDate: secondLastDate
        });

      let firstDataNum = 0;
      let secondDataNum = 0;
      response.data.data.forEach(f => {
        if (f.loans > 0 || f.projectedLoans > 0) {
          firstDataNum++;
        }
      });

      response.data.secondData.forEach(s => {
        if (s.loans > 0 || s.projectedLoans > 0) {
          secondDataNum++;
        }
      });
      this.lineChartHollowLoader = false;

      //Checking if first date selected or second date selected have any data or not.
      if (firstDataNum == 0 && secondDataNum == 0) {
        this.isNoLoanClosingData = true;
        return;
      }
      this.displayDateClosedLoansData(
        requestType,
        response.data.data,
        response.data.secondData.length > 0 ? response.data.secondData : null
      );
    } catch (error) {
      this.lineChartHollowLoader = false;
      console.log(error, "error");
    }
  }

  public async displayDateClosedLoansData(
    requestType,
    firstData,
    secondData = null
  ) {
    try {
      let chartData;
      if (secondData)
        chartData = await this.getLineChartDoubleData(
          requestType,
          firstData,
          secondData
        );
      else chartData = await this.getLineChartData(requestType, firstData);

      let chart = {
        color: ["#fff", "transparent"],
        height: 240,
        // width: 700,
        type: "line",
        shadow: {
          enabled: true,
          color: "#ff0000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 1
        },
        toolbar: {
          show: false
        }
      };
      chartData["charts"] = chart;

      this.lineChartInstance = new LineChartWithDataTables();
      this.lineChartInstance.renderDefault(
        chartData,
        "lineChartWithSingleData"
      );
    } catch (error) {
      console.log(error);
    }
  }

  public async getLineChartDoubleData(requestType, firstData, secondData) {
    try {
      let projectedClosedLoans = [];
      let actualClosedLoans = [];
      let expectedClosedLoans = [];
      let secondProjectedClosedLoans = [];
      let secondActualClosedLoans = [];
      let secondExpectedClosedLoans = [];
      let colors = [];
      let categories = [];
      let series = [];
      let stroke = [];
      let dataLength =
        firstData.length > secondData.length
          ? firstData.length
          : secondData.length;

      firstData.forEach((element, index) => {
        if (moment(element.weekStartDate).isBefore(moment().startOf("week"))) {
          expectedClosedLoans.push(element.projectedLoans);
          projectedClosedLoans.push(null);
          actualClosedLoans.push(element.loans);
        } else if (
          moment(element.weekStartDate).isSame(moment().startOf("week"))
        ) {
          expectedClosedLoans.push(element.projectedLoans);
          projectedClosedLoans.push(element.projectedLoans);
          actualClosedLoans.push(element.loans);
        } else {
          expectedClosedLoans.push(null);
          projectedClosedLoans.push(element.projectedLoans);
          actualClosedLoans.push(null);
        }
        if (dataLength == firstData.length) {
          let wNum = parseInt(element.weekCount) + 1;
          categories.push("Week" + wNum);
        }
      });

      secondData.forEach((element, index) => {
        if (moment(element.weekStartDate).isBefore(moment().startOf("week"))) {
          secondExpectedClosedLoans.push(element.projectedLoans);
          secondProjectedClosedLoans.push(null);
          secondActualClosedLoans.push(element.loans);
        } else if (
          moment(element.weekStartDate).isSame(moment().startOf("week"))
        ) {
          secondExpectedClosedLoans.push(element.projectedLoans);
          secondProjectedClosedLoans.push(element.projectedLoans);
          secondActualClosedLoans.push(element.loans);
        } else {
          secondExpectedClosedLoans.push(null);
          secondProjectedClosedLoans.push(element.projectedLoans);
          secondActualClosedLoans.push(null);
        }
        if (dataLength == secondData.length && dataLength != firstData.length) {
          let wNum = parseInt(element.weekCount) + 1;
          categories.push("Week" + wNum);
        }
      });

      let al = actualClosedLoans.filter(e => e != null && e != 0);
      if (al.length > 0) {
        series.push({
          name: "First Actual",
          data: actualClosedLoans
        });
        colors.push("#0066ff");
        stroke.push(0);
      }

      let pl = projectedClosedLoans.filter(e => e != null && e != 0);
      if (pl.length > 0) {
        series.push({
          name: "First Projected",
          data: projectedClosedLoans
        });
        colors.push("#33cc33");
        stroke.push(0);
      }

      let el = expectedClosedLoans.filter(e => e != null && e != 0);
      if (el.length > 0) {
        series.push({
          name: "First Expected",
          data: expectedClosedLoans
        });
        colors.push("#ff6600");
        stroke.push(0);
      }

      //////////////////************For second data******//////////////////////

      let sal = secondActualClosedLoans.filter(e => e != null && e != 0);
      if (sal.length > 0) {
        series.push({
          name: "Second Actual",
          data: secondActualClosedLoans
        });
        colors.push("#000066");
        stroke.push(8);
      }

      let spl = secondProjectedClosedLoans.filter(e => e != null && e != 0);
      if (spl.length > 0) {
        series.push({
          name: "Second Projected",
          data: secondProjectedClosedLoans
        });
        colors.push("#006600");
        stroke.push(8);
      }

      let sel = secondExpectedClosedLoans.filter(e => e != null && e != 0);
      if (sel.length > 0) {
        series.push({
          name: "Second Expected",
          data: secondExpectedClosedLoans
        });
        colors.push("#990000");
        stroke.push(8);
      }

      let chartData = {
        stroke: stroke,
        colors: colors,
        series: series,
        categories: categories,
        title: this.getLoanCLosingLabel(requestType),
        xTitle: "Week",
        yTitle: "Number of Loans"
      };

      return chartData;
    } catch (error) {
      console.log(error, "error");
    }
  }

  public getLoanCLosingLabel(requestType) {
    try {
      let months = [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];

      switch (requestType) {
        case "view":
          return `Data for month : ${this.firstSelectedMonth.name} ${this.firstSelectedYear}`;

        case "viewDateRanges":
          let startDate =
            new Date(this.firstDateRangeStartDate).getDate() +
            " " +
            months[new Date(this.firstDateRangeStartDate).getMonth()] +
            " " +
            new Date(this.firstDateRangeStartDate).getFullYear();
          let endDate =
            new Date(this.firstDateRangeLastDate).getDate() +
            " " +
            months[new Date(this.firstDateRangeLastDate).getMonth()] +
            " " +
            new Date(this.firstDateRangeLastDate).getFullYear();
          return `Data for date range : ${startDate} to ${endDate}`;

        case "compare":
          return `Data for months : First - ${this.firstSelectedMonth.name} ${this.firstSelectedYear}, Second - ${this.secondSelectedMonth.name} ${this.secondSelectedYear}`;

        case "compareDateRanges":
          let firstStartDate =
            new Date(this.firstDateRangeStartDate).getDate() +
            " " +
            months[new Date(this.firstDateRangeStartDate).getMonth()] +
            " " +
            new Date(this.firstDateRangeStartDate).getFullYear();
          let firstEndDate =
            new Date(this.firstDateRangeLastDate).getDate() +
            " " +
            months[new Date(this.firstDateRangeLastDate).getMonth()] +
            " " +
            new Date(this.firstDateRangeLastDate).getFullYear();
          let secondStartDate =
            new Date(this.secondDateRangeStartDate).getDate() +
            " " +
            months[new Date(this.secondDateRangeStartDate).getMonth()] +
            " " +
            new Date(this.secondDateRangeStartDate).getFullYear();
          let secondEndDate =
            new Date(this.secondDateRangeLastDate).getDate() +
            " " +
            months[new Date(this.secondDateRangeLastDate).getMonth()] +
            " " +
            new Date(this.secondDateRangeLastDate).getFullYear();
          return `Data for date ranges : First -${firstStartDate} to ${firstEndDate}, Second - ${secondStartDate} to ${secondEndDate}`;
        default:
          return "Data for past 6 weeks to next 6 weeks";
      }
    } catch (error) {
      console.log(error, "error");
    }
  }

  public getLineChartData(requestType, data) {
    try {
      let projectedClosedLoans = [];
      let actualClosedLoans = [];
      let expectedClosedLoans = [];
      let colors = [];
      let categories = [];
      let series = [];
      let stroke = [];
      data.forEach((element, index) => {
        if (moment(element.weekStartDate).isBefore(moment().startOf("week"))) {
          expectedClosedLoans.push(element.projectedLoans);
          projectedClosedLoans.push(null);
          actualClosedLoans.push(element.loans);
        } else if (
          moment(element.weekStartDate).isSame(moment().startOf("week"))
        ) {
          expectedClosedLoans.push(element.projectedLoans);
          projectedClosedLoans.push(element.projectedLoans);
          actualClosedLoans.push(element.loans);
        } else {
          expectedClosedLoans.push(null);
          projectedClosedLoans.push(element.projectedLoans);
          actualClosedLoans.push(null);
        }
        categories.push(element.weekName);
      });

      let al = actualClosedLoans.filter(e => e != null && e != 0);
      if (al.length > 0) {
        series.push({
          name: "Actual",
          data: actualClosedLoans
        });
        colors.push("#0066ff");
        stroke.push(0);
      }

      let pl = projectedClosedLoans.filter(e => e != null && e != 0);
      if (pl.length > 0) {
        series.push({
          name: "Projected",
          data: projectedClosedLoans
        });
        colors.push("#33cc33");
        stroke.push(0);
      }

      let ecl = expectedClosedLoans.filter(e => e != null);
      let pcl = projectedClosedLoans.filter(e => e != null);
      let dataIndex = ecl.findIndex(e => e != null && e != 0);
      if (
        ecl.filter(e => e != 0).length > 1 ||
        (ecl.filter(e => e != 0).length == 1 &&
          dataIndex != -1 &&
          (dataIndex != ecl.length - 1 ||
            (pcl.length > 0
              ? dataIndex == ecl.length - 1 && ecl[dataIndex] != pcl[0]
              : dataIndex == ecl.length - 1)))
      ) {
        series.push({
          name: "Expected",
          data: expectedClosedLoans
        });
        colors.push("#ff6600");
        stroke.push(0);
      }

      let chartData = {
        stroke: stroke,
        colors: colors,
        series: series,
        categories: categories,
        title: this.getLoanCLosingLabel(requestType),
        xTitle: "Week",
        yTitle: "Number of Loans"
      };

      return chartData;
    } catch (error) {
      console.log(error);
    }
  }

  public async showProjectedLoansClosing() {
    let formValid = await this.$validator.validateAll();
    if (!formValid) {
      this.$snotify.error("Please fix errors first");
      return;
    }
    this.$modal.hide("loanProjectionModal");
    this.getActualAndProjectedClosing(this.projectedLoanType);
    this.projectedLoanType = null;
  }

  public openLoanProjectionModel() {
    this.clearPreviousDatas();
    this.$modal.show("loanProjectionModal");
  }

  public clearPreviousDatas() {
    this.firstSelectedYear = null;
    this.firstSelectedMonth = null;
    this.secondSelectedYear = null;
    this.secondSelectedMonth = null;
    this.firstSelectedMonthError = null;
    this.secondSelectedMonthError = null;
    this.firstDateRangeStartDate = null;
    this.firstDateRangeLastDate = null;
    this.secondDateRangeStartDate = null;
    this.secondDateRangeLastDate = null;
    this.firstSelectedDateRangeError = null;
    this.secondSelectedDateRangeError = null;
  }

  public async getSuperAdminDashboard() {
    let basis = moment(new Date()).toDate();
    let pipelineBasis: string = "all";
    this.getLoanOverViewReport(); //modified
    this.generatePieChartDataset(pipelineBasis, true); //modified
    this.percentageOfLoansClose(true); //modified
    this.loanSetupTurnTimeReport(basis); // modified
    this.caTurnTimeReport(basis); // modified
    this.closingTimeFrameReportForRefinance(basis); // modified
    this.closingTimeFrameReportForPurchase(basis); // modified
    this.getBrokerPullThrough(); // modified
    this.getLoanNotSubmittedCountByBroker(); //new Created
    this.getCriticalWarningCount(); //new Created
    this.getEmployeesBorrowerData(); /// pending
    this.getActualAndProjectedClosing(null);
    this.getAveragePullThroughbyBrokers(); //new Created
  }
  async mounted() {
    let currentYear = new Date().getFullYear();
    for (let i = 2019; i <= currentYear; i++) {
      this.possibleYears.push(i);
    }
    let lastYear = moment(new Date())
      .subtract(1, "years")
      .toDate();

    this.years = [this.currentYears, lastYear];
    await this.getSuperAdminDashboard();

    if (this.$loader) {
      this.$loader.hide();
    }
  }
} //Export Ending
