import ApexCharts from "apexcharts";
const fontFamily = "Conv_Montserrat-Regular";

export class ColumnChart {
  public option = {};
  public element: string;
  public chartInstance: ApexCharts;
  public buildOptions(data: any) {
    return {
      series: [
        {
          name: "",
          data: data.series
        },
        {
          name: "",
          data: data.inactive
        }
      ],

      chart: {
        type: "bar",
        height: 280,
        width: data.width,
        margin: "0 auto",
        // width: data.series.length,
        stacked: true,
        toolbar: {
          show: false
        },
        // zoom: {
        //     enabled: true,
        //     type: 'x',
        //     autoScaleYaxis: false,
        //     zoomedArea: {
        //         fill: {
        //             color: '#90CAF9',
        //             opacity: 0.4
        //         },
        //         stroke: {
        //             color: '#0D47A1',
        //             opacity: 0.4,
        //             width: 1
        //         }
        //     }
        // },
        noData: {
          text: "No Data",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#FFFFFF",
            fontSize: "14px",
            fontFamily: undefined
          }
        }
      },
      colors: data.colors,

      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: -100,
                to: -46,
                color: "#F15B46"
              },
              {
                from: -45,
                to: 0,
                color: "#FEB019"
              }
            ]
          },
          columnWidth: "5%",
          distributed: true
          //endingShape: 'rounded',
        }
      },
      stroke: {
        width: -1
      },
      dataLabels: {
        enabled: false
        // enabledOnSeries: [1]
      },

      grid: {
        show: true,
        borderColor: "#515153",
        strokeDashArray: 2,
        position: "back",
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      yaxis: {
        title: {
          text: "No of Borrowers",
          style: {
            color: "#ffffff",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            cssClass: "apexcharts-xaxis-title"
          }
        },
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 120,
          style: {
            color: "#FFFFFF",
            fontSize: "12px",
            fontFamily: fontFamily
          },

          formatter: function(y) {
            return y.toFixed(0);
          }
        }
      },
      xaxis: {
        type: "category",
        categories: data.categories,
        labels: {
          rotate: -45,
          rotateAlways: false,
          // hideOverlappingLabels: true,
          showDuplicates: true,
          trim: true,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: "#FFFFFF",
            fontSize: "12px",
            fontFamily: fontFamily
          }
        }
      },
      legend: {
        show: false,
        position: "top",
        horizontalAlign: "right",
        labels: {
          colors: "#FFFFFF",
          useSeriesColors: false
        },
        onItemHover: {
          highlightDataSeries: true
        },
        onItemClick: {
          toggleDataSeries: true
        }
      },
      title: {
        align: "left",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "16px",
          color: "#FFFFFF"
        }
      },
      responsive: [
        {
          breakpoint: 1199,
          options: {
            chart: {
              // width: 3000,
              height: 280,
              marginRight: 15
            }
          }
        },

        {
          breakpoint: 991,
          options: {
            chart: {
              // width: 3000,
              height: 270,
              marginLeft: 15
            }
          }
        },
        {
          breakpoint: 767,
          options: {
            chart: {
              height: 250,
              marginLeft: 15
            }
          }
        }
      ],
      tooltip: {
        // enabled: true,
        // offsetY: -35,
        // theme: 'dark',
        enabled: true,
        fillSeriesColor: true,
        theme: "dark",
        y: [
          {
            title: {
              formatter: function(val) {
                return "Completed";
              }
            }
          },
          {
            title: {
              formatter: function(val) {
                return "Falloff";
              }
            }
          }
        ]
      },

      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      },
      toolbar: {
        show: true,
        theme: "dark",
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: []
        },
        autoSelected: "zoom"
      }
    };
  }

  constructor(data: any, elem: string) {
    this.element = elem;
    this.option = this.buildOptions(data);
    this.renderDefault();
  }

  renderDefault() {
    this.chartInstance = new ApexCharts(
      document.querySelector(`#${this.element}`),
      this.option
    );
    this.chartInstance.render();
  }

  updateColumnChart(data: any) {
    this.chartInstance.updateOptions(this.buildOptions(data));
  }
  destroyChart() {
    this.chartInstance.destroy();
  }
}
